<template>
  <v-container class="fadeIn">
    <HeadlineTableView
        :icon="'mdi-cogs'"
        :headline="'Einstellungen'"
    ></HeadlineTableView>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row justify="center">
      <v-expansion-panels accordion class="rounded-xl pa-8">
        <v-expansion-panel active>
          <v-expansion-panel-header><h3>Benutzereinstellungen</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col cols="12" md="6">
              <!--<UploadPicture class="pb-16">
              </UploadPicture>-->
              <KeyValueBlock
                  :key-values="getUserSettings()"
                  @editEntry="editEntry"
              >
              </KeyValueBlock>
              <div class="row  pt-3">
                <div class="col-4">
                  Passwort ändern:
                </div>

                <div class="col-8">
                  <a @click="dialogPassword=true">Passwort ändern</a>
                </div>
                <ChangePassword :dialog="dialogPassword" @hideDialog="dialogPassword=false"></ChangePassword>
              </div>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header><h3>Individuelle Felder</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <CustomFieldSettings :user="user"></CustomFieldSettings>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><h3>Rechnungen und Angebote</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <InvoiceSettings :user="user"></InvoiceSettings>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><h3>Status und Kategorien</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <StatusAndFieldSettings :user="user"></StatusAndFieldSettings>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Unternehmens
              <wbr>
              einstellungen
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <KeyValueBlock
                  :key-values="getCompanySettings()"
                  @editEntry="editEntry">
              </KeyValueBlock>
              <v-row>
                <v-col cols="4">
                  Unternehmenslogo:
                </v-col>
                <v-col cols="8">
                  <v-img
                      :src="profilePic"
                      max width="350px"
                      class="d-inline-flex"
                  />
                  <br>
                  <v-btn
                      color="primary"
                      class=""
                      @click="onButtonClick"
                  >
                    <v-icon left>
                      mdi-upload
                    </v-icon>
                    Datei hochladen
                  </v-btn>
                  <v-btn
                      v-if="profilePic"
                      color="primary"
                      class=""
                      @click="deleteProfilePic"
                  >
                    <v-icon left>
                      mdi-cancel
                    </v-icon>
                    Bild löschen
                  </v-btn>
                </v-col>
              </v-row>

              <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=""
                  @change="uploadPic"
              >
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              Zahlung, Rechnungen und Abonnement
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col>
                  <h4>Vertrag zur Auftragsverarbeitung</h4>
                  <a  @click="$router.push('/avv/'+user.id)">Download PDF</a>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col cols="12" md="8">
                  <h4>Limits</h4>
                  <h5>Speicherplatz</h5>
                  <v-progress-linear
                      height="8px"
                      rounded
                      color="primary"
                      :value="getPercent(bytesToGB(usedMemory),10)"
                      v-if="user.paymentPlan === 0 ||user.paymentPlan === 1"
                  ></v-progress-linear>
                  <v-progress-linear
                      height="8px"
                      rounded
                      color="primary"
                      :value="getPercent(bytesToGB(usedMemory),20)"
                      v-else
                  ></v-progress-linear>

                  {{ bytesToGB(usedMemory) }} GB
                  /
                  <span v-if="user.paymentPlan === 0||user.paymentPlan === 1">10 GB</span>
                  <span v-if="user.paymentPlan === 2">20 GB</span>

                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <div v-if="user.paymentPlan === 0">
                <p>Deine Testphase <b>{{ getDays(user.created) }}</b>. Wähle jetzt einen Plan, um
                  direkt weiter planen zu können.</p>
                <v-btn class="primary" @click="$router.push('/testphase-abgelaufen')">Plan wählen</v-btn>
              </div>
              <div v-if="user.paymentPlan === 1">
                <div v-if="user.stripeId !== ''">
                  <p>Du nutzt den <b> Essential Plan</b>.</p>
                  <div v-if="portalUrl">
                    <v-btn class="primary" @click="goTo(portalUrl)">Abonement verwalten</v-btn>
                  </div>
                </div>
                <div v-else>
                  Dein Konto wird gerade eingerichtet. Schaue in ein paar Tagen wieder vorbei, um deine
                  Zahlungseinstellungen selbst verwalten zu können.
                </div>
              </div>
              <div v-if="user.paymentPlan === 2">
                <div v-if="user.stripeId !== ''">
                  <p>Du nutzt den <b> Premium Plan</b>.</p>
                  <div v-if="portalUrl !== ''">
                    <v-btn class="primary" @click="goTo(portalUrl)">Abonement verwalten</v-btn>
                  </div>
                  <Spinner v-else></Spinner>
                </div>
                <div v-else>
                  Dein Konto wird gerade eingerichtet. Schaue in ein paar Tagen wieder vorbei, um deine
                  Zahlungseinstellungen selbst verwalten zu können.
                </div>
              </div>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {msgObj, error, success} from "@/helper/helper";
const Spinner = () => import("@/components/generalUI/Spinner");
const Message = () => import("@/components/generalUI/Message");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const StatusAndFieldSettings = () => import("@/components/entities/Settings/statusAndFieldSettings/StatusAndFieldSettings");
const CustomFieldSettings = () => import("@/components/entities/Settings/CustomFieldSettings");
const ChangePassword = () => import("@/components/entities/Settings/ChangePassword");
const InvoiceSettings = () => import("@/components/entities/Settings/InvoiceSettings");


export default {
  name: "Dashboard",
  components: {
    KeyValueBlock,
    HeadlineTableView,
    Message,
    StatusAndFieldSettings,
    CustomFieldSettings,
    ChangePassword,
    Spinner,
    InvoiceSettings
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('payment', {
      portalUrl: 'portalUrl',
    }),
    ...mapGetters('file', {
      usedMemory: 'memoryUsed',
      profilePic: 'profilePic'
    }),
  },
  data() {
    return {
      message: msgObj(),
      dialogPassword: false,
      upload: null
    }
  },
  mounted() {
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      if (this.user.paymentPlan > 0 && this.user.stripeId !== '') {
        this.$store.dispatch('payment/getPortalForUser', {
          stripeId: this.user.stripeId,
        }).catch((err) => {
          this.message = error(err)
        })
      }
      this.$store.dispatch('file/getMemoryUsed', {
        uid: res,
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('file/getProfilePic', {
        uid: res,
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})
      this.$refs.uploader.click()
    },
    deleteProfilePic(){
      this.$store.dispatch('file/deleteProfilePic').then(() => {
      }).catch((err) => {
        this.message = error(err)
      }).then(()=>{
        this.message = success('Bild wurde erfolgreich gelöscht.')
        this.$store.dispatch('file/getProfilePic').then(() => {
        }).catch((err) => {
          this.message = error(err)
        })
      })
    },
    uploadPic(e) {
      let file = e.target.files[0]
        if (file.size < 5097152) {
            this.$store.dispatch('file/createOrUpdateProfilePic', {file, uid: this.user.id}).then(() => {
            }).catch((err) => {
              this.message = error(err)
            }).then(()=>{
              this.message = success('Datei wurde erfolgreich hochgeladen.')
              this.$store.dispatch('file/getProfilePic').then(() => {
              }).catch((err) => {
                this.message = error(err)
              })
            })
        } else {
          this.message = error('Datei darf maximal 5MB haben.')
        }

    },
    goTo(url) {
      window.location.href = url
    },
    editEntry(payload) {
      this.$store.dispatch('customer/editField', {
        entity: "planner",
        id: this.user.id,
        newValue: payload.newValue,
        row: payload.row
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getPercent(value, total){
      return (value/total)*100
    },
    bytesToGB(byte){
      return (byte / (1000 * 1000 * 1000)).toFixed(2);
    },
    getDays(date) {
      let number = 14 - Math.ceil((Date.now() - date) / (1000 * 3600 * 24));
      if (number === 1) {
        return 'läuft in einem Tag ab'
      } else if (number === 0) {
        return 'läuft heute ab'
      } else if (number < 0) {
        return 'ist abgelaufen'
      } else {
        return 'läuft in ' + number + ' Tagen ab'
      }
    },
    getUserSettings() {
      return [
        {
          title: 'Vorname',
          value: this.user.firstname,
          editBtn: {
            icon: 'mdi-check-circle',
            entity: 'settings',
            row: 'firstname'
          }
        },
        {
          title: 'Nachname',
          value: this.user.lastname,
          editBtn: {
            icon: 'mdi-check-circle',
            entity: 'settings',
            row: 'lastname'
          }
        },
      ]
    },
    getCompanySettings() {
      return [
        {
          title: 'Name',
          value: this.user.companyName,
          editBtn: {
            icon: 'mdi-check-circle',
            entity: 'settings',
            row: 'companyName'
          }
        },
      ]
    }
  }
}
</script>

<style lang="sass" scoped></style>
